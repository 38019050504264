import instance from "./axios"

export const uploadMedia = (media) => {
  const formData = new FormData()
  formData.append("file", media)

  return instance.post("/media/upload", formData)
}

export const getBrands = () => instance.get("/panel/brands")

export const getStatistics = (searchParams) => {
  return instance.get(`/panel/statistics?${searchParams}`)
}

export const getSutokProducts = (searchParams) => {
  return instance.get(`/panel/sutok-products?${searchParams}`)
}

export const getSutokProductsCounts = () => {
  return instance.get(`/panel/sutok-products-counts`)
}

export const listUsers = (searchParams) =>
  instance.get(`/panel/users?${searchParams}`)

export const listArticles = (searchParams) =>
  instance.get(`/panel/articles?${searchParams}`)

export const getArticle = (id) => instance.get(`/panel/articles/${id}`)

export const saveArticle = (dto) => instance.post(`/panel/articles`, dto)

export const updateArticle = ([id, dto]) =>
  instance.put(`/panel/articles/${id}`, dto)

export const listSellers = (searchParams) =>
  instance.get(`/panel/sellers?${searchParams}`)

export const getUserDetail = (userId) =>
  instance.get(`/panel/userDetail/${userId}`)


export const getUserOrders = (searchParams) =>
  instance.get(`/panel/userOrders?${searchParams}`)

export const listOffers = (searchParams) =>
  instance.get(`/panel/offers?${searchParams}`)

export const listAdverts = (searchParams) =>
  instance.get(`/panel/adverts?${searchParams}`)

export const getAdvert = (id) =>
  instance.get(`/panel/advert/${id}`)

export const listCargos = (searchParams) =>
  instance.get(`/panel/getCargos?${searchParams}`)

export const listSliders = () => instance.get(`/panel/sliders`)

export const createSlider = (createSliderDto) =>
  instance.post(`/panel/sliders`, createSliderDto)

export const updateSlider = (sliderId, updateSliderDto) =>
  instance.put(`/panel/sliders/${sliderId}`, updateSliderDto)

export const deleteSlider = (sliderId) =>
  instance.post(`/panel/sliders/delete/${sliderId}`)

export const listCategories = (searchParams) =>
  instance.get(`/panel/categories?${searchParams}`)

export const findCategoryById = (id) =>
  instance.get(`/panel/categories/${id}`)

export const createCategory = (createCategoryDto) =>
  instance.post(`/panel/categories`, createCategoryDto)

export const updateCategory = (categoryId, updateCategoryDto) =>
  instance.put(`/panel/categories/${categoryId}`, updateCategoryDto)

export const deleteCategory = (categoryId) =>
  instance.post(`/panel/categories/delete/${categoryId}`)

export const swapCategories = (dto) =>
  instance.put(`/panel/categories/order-swap`, dto)

export const swapHomeSections = (dto) =>
  instance.put(`/panel/home-sections/order-swap`, dto)

export const listModels = (searchParams) =>
  instance.get(`/panel/models?${searchParams}`)

export const getModelDetails = (modelId) =>
  instance.get(`/panel/getModelDetails/${modelId}`)

export const createModel = (dto) => instance.post(`/panel/models`, dto)

export const updateModel = (modelId, dto) =>
  instance.put(`/panel/models/${modelId}`, dto)

export const deleteModel = (modelId) =>
  instance.post(`/panel/models/delete/${modelId}`)

export const listOrders = (searchParams) =>
  instance.get(`/panel/orders?${searchParams}`)

export const getOrderDetails = (id) =>
  instance.get(`/panel/order/${id}`)

export const listProductGroups = (searchParams) =>
  instance.get(`/panel/product-groups?${searchParams}`)

export const getProductGroupDetails = (groupId) =>
  instance.get(`/panel/product-detail/${groupId}`)

export const listHomeSections = (searchParams) =>
  instance.get(`/panel/home-sections?${searchParams}`)

export const getBrandDetail = (brandId) =>
  instance.get(`/brands/${brandId}`)

export const getSize = (sizeId) => {
  return instance.get(`product/size/${sizeId}`)
}

export const createSection = (dto) => instance.post(`/panel/home-sections`, dto)

export const updateSection = (sectionId, dto) =>
  instance.put(`/panel/home-sections/${sectionId}`, dto)

export const deleteSection = (sectionId) =>
  instance.delete(`/panel/home-sections/${sectionId}`)

export const getGroupAttributeInformations = (groupId) =>
  instance.get(`/panel/group-attributes/${groupId}`)

export const listSales = (searchParams) =>
  instance.get(`/panel/sales?${searchParams}`)

export const getAttributes = (searchParams) =>
  instance.get(`/panel/attribute?${searchParams}`)

export const createAttribute = (createAttributeDto) =>
  instance.post(`/panel/attribute`, createAttributeDto)

export const updateAttribute = (attributeId, updateAttributeDto) =>
  instance.put(`/panel/attribute/${attributeId}`, updateAttributeDto)

export const deleteAttribute = (attributeId) =>
  instance.post(`/panel/attribute/delete/${attributeId}`)

export const listTerms = (attributeId, searchParams) =>
  instance.get(`/panel/terms/${attributeId}?${searchParams}`)

export const createTerm = (createTermDto) =>
  instance.post(`/panel/terms`, createTermDto)

export const updateTerm = (termId, updateTermDto) =>
  instance.put(`/panel/terms/${termId}`, updateTermDto)

export const deleteTerm = (termId) =>
  instance.post(`/panel/terms/delete/${termId}`)

export const listBrands = (searchParams) =>
  instance.get(`/panel/brands?${searchParams}`)

export const createBrand = (createBrandDto) =>
  instance.post(`/panel/brands`, createBrandDto)

export const updateBrand = (brandId, updateBrandDto) =>
  instance.put(`/panel/brands/${brandId}`, updateBrandDto)

export const deleteBrand = (brandId) =>
  instance.post(`/panel/brands/delete/${brandId}`)

export const listProducts = (groupId, searchParams) =>
  instance.get(`/panel/products/${groupId}?${searchParams}`)

export const getUserById = (userId) => instance.get(`/panel/users/${userId}`)

export const createGroup = async (createGroupDto) => {
  return await instance.post(`/panel/group`, createGroupDto)
}

export const filterGroups = (searchParams) =>
  instance.get(`/panel/filterGroups/?${searchParams}`)

export const copyVariant = (dto) =>
  instance.post(`/panel/group/copy-variant`, dto)

export const updateGroup = (groupId, updateGroupDto) =>
  instance.put(`/panel/group/${groupId}`, updateGroupDto)

export const deleteGroup = (groupId) =>
  instance.delete(`/panel/group/${groupId}`)

export const deleteProduct = (productId) =>
  instance.post(`/panel/products/delete/${productId}`)

export const createProduct = (createProductDto) =>
  instance.post(`/panel/products/`, createProductDto)

export const updateProduct = (productId, updateProductDto) => {
  return instance.put(`/panel/products/${productId}`, updateProductDto);
}

export const updateOrder = (orderId, updateOrderDto) =>
  instance.put(`/panel/orders/update/${orderId}`, updateOrderDto)

export const login = (loginDto) => instance.post(`/auth/login/`, loginDto)

export const getSaleSettings = () => instance.get("/panel/sale-settings")

export const updateSaleSettings = (dto) =>
  instance.put("/panel/sale-settings", dto)

export const verifyUser = (userId) =>
  instance.put(`/panel/users/${userId}/verify`)

export const denyUser = (userId) => instance.put(`/panel/users/${userId}/deny`)
export const updateUserFees = (userId, commissionFee, serviceFee) => {
  instance.post(`/panel/updateUserFees`, userId, commissionFee, serviceFee)
}

export const saveAgreement = (dto) => instance.put("/panel/agreement", dto)

export const getAgreement = (type) =>
  instance.get("/panel/agreement/" + type)

export const updateStockState = () =>
  instance.put("/panel/updateStockState/")

export const createUserComment = (body) => {
  return instance.post("/panel/createUserComment", body)
}

export const listVariants = (groupId) => {
  return instance.get(`/panel/listVariants/${groupId}`);
}

export const listAllProductattributes = () => {
  return instance.get(`/panel/listAllProductattributes`);
}

export const listAllProductattributeterms = (attributeId) => {
  return instance.get(`/panel/listAllProductattributeterms/${attributeId}`);
}

export const getTerm = (id) => {
  return instance.get(`/panel/getTerm/${id}`);
}

export const listAllSellerPayment = (searchParams) => {
  return instance.get(`/panel/sellerPayments?${searchParams}`);
}

export const getSellerPayment = (id) => {
  return instance.get(`/panel/sellerPayments/${id}`);
}

export const createSellerPayment = (dto) => {
  return instance.post(`/panel/sellerPayments`, dto);
}

export const updateSellerPayment = (id, dto) => {
  return instance.put(`/panel/sellerPayments/${id}`, dto);
}

export const updateUser = (id, dto) => {
  console.log(dto)
  return instance.put(`/panel/users/${id}`, dto);
}

export const listDiscountCoupons = (searchParams) =>
  instance.get(`/discountcoupons?${searchParams}`);

export const createDiscountCoupon = (createDto) =>
  instance.post(`/discountcoupons`, createDto)

export const updateDiscountCoupon = (id, data) =>
  instance.put(`/discountcoupons/${id}`, data)

export const deleteDiscountCoupon = (id) =>
  instance.delete(`/discountcoupons/${id}`)

export const listProductsPendingApproval = (searchParams) =>
  instance.get(`/panel/productsPendingApproval?${searchParams}`)

export const createProductsPendingApproval = (createDto) =>
  instance.post(`/panel/productsPendingApproval`, createDto)

export const deleteProductsPendingApproval = (id) =>
  instance.delete(`/panel/productsPendingApproval/${id}`)

export const createAdvert = async (product) => {
  return await instance.post("/advert", product)
}

export const updateAllOrders = async () => {
  return await instance.get("/panel/updateAllOrders")
}

export const listSellerRequests = (searchParams) => {
  return instance.get(`/panel/sellerRequests?${searchParams}`)
}

export const getSellerRequest = (id) => {
  return instance.get(`/panel/sellerRequests/${id}`)
}

export const updateSellerRequest = (id, body) => {
  return instance.put(`/panel/sellerRequests/${id}`, body)
}

export const deleteSellerRequest = (id) => {
  return instance.delete(`/panel/sellerRequests/${id}`)
}

export const createUser = (body) => {
  return instance.post(`/auth/register/`, body)
}

export const newUserSendPassword = (body) => {
  return instance.post(`/auth/newUserSendPassword`, body)
}

export const getUserAdverts = (searchParams) => {
  return instance.get(`/advert?${searchParams}`)
}

export const getProductSellers = (groupId) => {
  return instance.get(`/advert/getProductSellers/${groupId}`)
}

export const getAnalysis = (id) => {
  return instance.get(`/analysis/${id}`)
}

export const listAnalysis = (searchParams) => {
  return instance.get(`/analysis?${searchParams}`)
}

export const createNotification = async (body) => {
  return instance.post(`/notification`, body);
}

export const listNotifications = async (searchParams) => {
  return instance.get(`/notification?${searchParams}`);
}

export const listNotificationsForAdminPanel = async (searchParams) => {
  return instance.get(`/notification/listNotificationsForAdminPanel?${searchParams}`);
}

export const deleteNotificationFindUuid = async (id) => {
  return instance.delete(`/notification/uuidDelete/${id}`);
}