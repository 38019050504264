import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import QuestionDialog from "../screens/Users/LogoutDialog";
import { drawerOpenAtom, userAtom } from "../utils/atoms";
import { useAtom } from "jotai";
import { makeStyles } from "@material-ui/core";
import { AiOutlineUser } from "react-icons/ai";
import { FiEdit } from "react-icons/fi";
import { MdOutlineInsertPhoto } from "react-icons/md";
import { GoPin } from "react-icons/go";
import Logo from "../images/logo.PNG";
import { MdOutlineCategory } from "react-icons/md";
import { PiNewspaperClippingLight } from "react-icons/pi";
import { MdOutlinePrivacyTip } from "react-icons/md";
import { PiCookieLight } from "react-icons/pi";
import { CiDeliveryTruck } from "react-icons/ci";
import { AiOutlineShop } from "react-icons/ai";
import { MdOutlineVerifiedUser } from "react-icons/md";
import { IoDocumentTextOutline } from "react-icons/io5";
import { IoExitOutline } from "react-icons/io5";
import { BsBank } from "react-icons/bs";
import DrawerItem from "./DrawerItem";
import AgreementsDrawer from "./AgreementsDrawer";
import {
  Analyse,
  Customers,
  Hide,
  Home,
  Models,
  Orders,
  Product,
  Settings,
} from "./Svg";
import { FaBell, FaCheck, FaTag } from "react-icons/fa6";
import { useQuery } from "react-query";
import * as Queries from '../utils/queries'

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  background: "#000000",
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export const useStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    background: "#000000",
  },
  userPhotoSmall: {
    width: 40,
    height: 40,
    borderRadius: "50%",
  },
  headerButtons: {
    marginLeft: "auto",
    display: "flex",
    alignItems: "center",
  },
  exitIcon: {
    cursor: "pointer",
  },
}));

export default function MiniDrawer() {
  const [open, setOpen] = useAtom(drawerOpenAtom);
  const [openQuestionDialog, setOpenQuestionDialog] = React.useState(false);
  const [user, setUser] = useAtom(userAtom);
  const classes = useStyles();
  const [selectedItem, setSelectedItem] = React.useState();
  const onLogoutClick = () => {
    setOpenQuestionDialog(true);
  };

  const { data: updatedAllOrders } = useQuery(
    ["updateAllOrders"],
    () => Queries.updateAllOrders(),
    {
      onError: (err) => {
        console.log("err", err);
      },
      placeholderData: null,
    }
  );

  const items = [
    {
      text: "Anasayfa Düzeni",
      icon: <Home />,
      path: "/home-sections",
    },
    {
      text: "Analizler",
      icon: <Analyse />,
      path: "/reports",
      public: true,
    },
    {
      text: "Siparişler",
      icon: <Orders />,
      path: "/orders",
      public: true,
    },
    {
      text: "Kategoriler",
      path: "/categories",
      icon: <MdOutlineCategory />,
    },
    {
      text: "Modeller",
      path: "/models",
      icon: <Models />,
    },
    {
      text: "Markalar",
      path: "/brands",
      icon: <GoPin />,
    },
    {
      text: "Ürünler",
      path: "/products",
      icon: <Product />,
      public: true,
    },
    {
      text: "Kapak Fotoğrafları",
      path: "/sliders",
      icon: <MdOutlineInsertPhoto />,
    },
    {
      text: "Ürün Özellikleri",
      path: "/attributes",
      icon: <FiEdit size={14} />,
    },
    {
      text: "Kullanıcılar",
      icon: <Customers />,
      path: "/users",
    },
    {
      text: "Bildirimler",
      icon: <FaBell />,
      path: "/notifications",
    },
    {
      text: "Ödemeler",
      icon: <BsBank />,
      path: "/seller-payments",
      public: true,
    },
    {
      text: "İndirim Kuponları",
      icon: <FaTag />,
      path: "/discount-coupons",
    },
    {
      text: "Onay Bekleyen Ürünler",
      icon: <FaCheck />,
      path: "/productsPendingApproval",
    },
    {
      text: "Satıcı Talepleri",
      icon: <FaBell />,
      path: "/sellerRequests",
    },
  ];

  const items2 = [
    {
      text: "Satıcılar",
      icon: <MdOutlineVerifiedUser />,
      path: "/sellers",
    },
    {
      text: "Sutok Ürünler",
      icon: <AiOutlineShop />,
      path: "/sutok-products",
    },
    // {
    //   text: "Teklifler",
    //   icon: <TrendingDownOutlined />,
    //   path: "/offers",
    // },

    {
      text: "Kargo Durumu",
      icon: <CiDeliveryTruck />,
      path: "/cargo",
    },
    {
      text: "Satış Ayarları",
      icon: <Settings />,
      path: "/sale-settings",
    },
    {
      text: "Blog Yazıları",
      path: "/articles",
      icon: <IoDocumentTextOutline />,
    },
    {
      text: "Gizlilik Politikası",
      icon: <MdOutlinePrivacyTip />,
      path: "/privacy-policy",
      onPress: () => {
        setSelectedItem({
          title: "Gizlilik Politikası",
          type: "PRIVACY_POLICY",
        });
      },
    },
    {
      text: "Şartlar ve Koşullar",
      icon: <PiNewspaperClippingLight />,
      path: "/terms-and-conditions",
      onPress: () => {
        setSelectedItem({
          title: "Şartlar ve Koşullar",
          type: "TERMS_AND_CONDITIONS",
        });
      },
    },
    {
      text: "Çerez Kullanımı",
      icon: <PiCookieLight />,
      path: "/cookie-policy",
      onPress: () => {
        setSelectedItem({
          title: "Çerez Kullanımı",
          type: "COOKIE_POLICY",
        });
      },
    },
    {
      text: "Çıkış Yap",
      icon: <IoExitOutline size={18} />,
      onPress: onLogoutClick,
      public: true,
    },
  ];

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar position="fixed" open={open}>
          <Toolbar>
            <img
              src={Logo}
              alt=""
              className={` ${open
                ? "hidden"
                : "w-[50px] object-contain h-[50px] cursor-pointer absolute left-[5px]"
                }`}
              onClick={handleDrawerOpen}
            />

            <Typography
              variant="h6"
              noWrap
              style={{ marginLeft: open ? "0" : "50px" }}
            >
              Sutok | Panel
            </Typography>
          </Toolbar>
        </AppBar>

        <Drawer variant="permanent" open={open}>
          <DrawerHeader style={{ backgroundColor: "#000000" }}>
            <div className="flex h-[63px] items-center justify-center p-2 bg-[#000000] w-[250px]">
              <img
                src={Logo}
                alt=""
                className=" h-[60px] w-[120px] pt-2 object-cover"
              />
            </div>
            <IconButton onClick={handleDrawerClose} style={{ zIndex: "15" }}>
              <Hide />
            </IconButton>
          </DrawerHeader>
          <Divider />
          <List
            className={`pb-24 relative ${user?.role == "SELLER" ? "h-full" : "h-max overflow-auto"}`}
            style={{ backgroundColor: "#000000", color: "#faf9f9" }}
          >
            {items
              .filter((item) => {
                return (
                  user.role === "ADMIN" ||
                  (user.role === "SELLER" && item.public)
                );
              })
              .map((item) => (
                <DrawerItem item={item} open={open} />
              ))}
            <Divider />
            <div className="mb-12">
              {items2
                .filter((item) => {
                  return (
                    user.role === "ADMIN" ||
                    (user.role === "SELLER" && item.public)
                  );
                })
                .map((item) => (
                  <DrawerItem item={item} open={open} />
                ))}
            </div>
            <li
              className={`fixed bottom-0 left-0 pl-0 flex flex-row items-center gap-2 bg-[#000000] py-3 border-t border-gray-600 ${open ? "min-w-[240px]" : "min-w-[65px]"}`}
            >
              <AiOutlineUser size={23} />{" "}
              <span className={`${open ? "text-sm" : "hidden"}`}>{user?.email}</span>
            </li>
          </List>
        </Drawer>

        <QuestionDialog
          title={"Çıkış yapmak istediğinize emin misiniz?"}
          openQuestionDialog={openQuestionDialog}
          onYesPress={() => {
            setOpenQuestionDialog(false);
            setUser(null);
          }}
          onNoPress={() => {
            setOpenQuestionDialog(false);
          }}
        />
      </Box>

      {!!selectedItem && (
        <AgreementsDrawer
          selectedItem={selectedItem}
          closeDrawer={() => setSelectedItem(null)}
        />
      )}
    </>
  );
}
