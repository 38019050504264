import { TableCell, TableRow } from "@material-ui/core";
import moment from "moment/moment";
import React from "react";
import * as Queries from '../../utils/queries'
import toast from "react-hot-toast";
import { FaCircleCheck, FaTrash } from "react-icons/fa6";
import { v4 as uuidv4 } from 'uuid'

const SellerRequestCard = ({ sellerRequestData, sellerRequestsRefetch }) => {

    const handleAccept = async () => {
        if (sellerRequestData?.createdBy) {
            const response = await Queries.updateUser(sellerRequestData?.createdBy?._id, { role: "SELLER" });
            if (response?._id) {
                const deletedResponse = await Queries.deleteSellerRequest(sellerRequestData?._id);
                if (deletedResponse?._id) {
                    toast.success("Talep başarılı bir şekilde kabul edildi")
                } else {
                    toast.error("Talep kabul edilirken bir hata meydana geldi. Lütfen tekrar deneyiniz")
                }
            } else {
                toast.error("Talep kabul edilirken bir hata meydana geldi. Lütfen tekrar deneyiniz")
            }
        } else {
            if (!sellerRequestData?.body) {
                const password = uuidv4();
                const newUserResponse = await Queries.createUser({
                    email: sellerRequestData?.email,
                    name: sellerRequestData?.name,
                    surname: sellerRequestData?.surname,
                    password: password,
                    models: [],
                    role: "SELLER",
                    ibanBankName: "-",
                    ibanAccountName: "-",
                    ibanNumber: "-",
                    companyName: "-",
                    vd: "-",
                    vkn: "-",
                    city: "-",
                    district: "-",
                    openAddress: "-",
                    postcode: "-",
                    identityNumber: "-",
                    phoneNumber: "-",
                    birthDate: new Date(),
                    gender: "MALE",
                    authType: "EMAIL"
                });

                if (newUserResponse?.user?._id) {
                    const newUserSendPasswordResponse = await Queries.newUserSendPassword({
                        email: sellerRequestData?.email,
                        name: sellerRequestData?.name,
                        surname: sellerRequestData?.surname,
                        password: password,
                    });

                    if (newUserSendPasswordResponse?.success) {
                        const deletedResponse = await Queries.deleteSellerRequest(sellerRequestData?._id);

                        if (deletedResponse?._id) {
                            toast.success("Talep başarılı bir şekilde kabul edildi")
                        } else {
                            toast.error("Talep kabul edilirken bir hata meydana geldi. Lütfen tekrar deneyiniz")
                        }
                    } else {
                        toast.error("Talep kabul edilirken bir hata meydana geldi. Lütfen tekrar deneyiniz")
                    }
                } else {
                    if (newUserResponse?.isUserExists) {
                        toast.error("Bu email adresine ait bir kullanıcı mevcut.")
                    } else {
                        toast.error("Talep kabul edilirken bir hata meydana geldi. Lütfen tekrar deneyiniz")
                    }
                }
            } else {
                const data = {
                    ...sellerRequestData?.body,
                    models: [],
                };
                const { passwordGain, ...restData } = data;
                const newUserResponse = await Queries.createUser(restData);

                if (newUserResponse?.user?._id) {
                    const newUserSendPasswordResponse = await Queries.newUserSendPassword({
                        email: sellerRequestData?.email,
                        name: sellerRequestData?.name,
                        surname: sellerRequestData?.surname,
                        password: sellerRequestData?.body?.password,
                    });

                    if (newUserSendPasswordResponse?.success) {
                        const deletedResponse = await Queries.deleteSellerRequest(sellerRequestData?._id);

                        if (deletedResponse?._id) {
                            toast.success("Talep başarılı bir şekilde kabul edildi")
                        } else {
                            toast.error("Talep kabul edilirken bir hata meydana geldi. Lütfen tekrar deneyiniz")
                        }
                    } else {
                        toast.error("Talep kabul edilirken bir hata meydana geldi. Lütfen tekrar deneyiniz")
                    }
                } else {
                    if (newUserResponse?.isUserExists) {
                        toast.error("Bu email adresine ait bir kullanıcı mevcut.")
                    } else {
                        toast.error("Talep kabul edilirken bir hata meydana geldi. Lütfen tekrar deneyiniz")
                    }
                }
            }
        }
        sellerRequestsRefetch();
    }

    const handleDelete = async () => {
        const response = await Queries.deleteSellerRequest(sellerRequestData?._id);
        if (response?._id) {
            toast.success("Talep başarılı bir şekilde silindi")
        } else {
            toast.error("Talep silinirken bir hata meydana geldi. Lütfen tekrar deneyiniz")
        }
        sellerRequestsRefetch();
    }

    return (
        <TableRow className="border-b hover:bg-gray-100 text-sm">
            <TableCell
                scope="row"
                class="px-3 py-3 text-gray-900"
            >
                {sellerRequestData?._id}
            </TableCell>
            <TableCell class="px-3 py-3">
                <div>{`${sellerRequestData?.name} ${sellerRequestData?.surname}`}</div>
                <div>{sellerRequestData?.email}</div>
            </TableCell>
            <TableCell class="px-3 py-3">
                {sellerRequestData?.message}
            </TableCell>
            <TableCell class="px-3 py-3">
                <div>{moment(sellerRequestData?.createdAt).format("LL")}</div>
                <div>{moment(sellerRequestData?.createdAt).format("hh:mm")}</div>
            </TableCell>
            <TableCell class="px-3 py-3 text-black">
                <div className="flex flex-row gap-3">
                    <button onClick={() => handleAccept()}>
                        <FaCircleCheck color="green" size={24} />
                    </button>

                    <button onClick={() => handleDelete()}>
                        <FaTrash color="red" size={24} />
                    </button>
                </div>
            </TableCell>
        </TableRow>
    );
};

export default SellerRequestCard;