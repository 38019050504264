import { useFormik } from 'formik'
import React, { useState } from 'react'
import { Form } from 'react-bootstrap'
import { useQuery } from 'react-query'
import * as yup from "yup"
import * as Queries from '../../utils/queries'
import Select from 'react-select'
import toast from 'react-hot-toast'

const AddSellerForm = () => {

    const [searchQuery, setSearchQuery] = useState({
        page: 1,
        limit: 10,
        filterName: ""
    })

    const sellerSchema = yup.object({
        name: yup.string().required("Lütfen Ad giriniz"),
        surname: yup.string().required("Lütfen Soyad giriniz"),
        email: yup.string().email("Geçerli bir e-posta adresi girin").required("Lütfen Email giriniz"),
        password: yup.string().required("Lütfen Şifre giriniz"),
        models: yup.array().required("Lütfen Model seçiniz"),
    })

    const {
        data: { page: models, totalModels, totalPage },
        refetch: modelsRefetch
    } = useQuery(
        ["LIST_MODELS", searchQuery],
        () => {
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;

            return Queries.listModels(
                new URLSearchParams({
                    ...searchQuery,
                }).toString()
            );
        },
        {
            placeholderData: {
                page: [],
                totalModels: 0,
                totalPage: 0,
            },
            keepPreviousData: false,
            onError: (err) => {
                console.log("err", err);
            },
        }
    );

    const { handleSubmit, handleChange, handleReset, values, errors, touched, setValues } = useFormik({
        initialValues: {
            name: "",
            surname: "",
            email: "",
            password: "",
            models: ""
        },
        onSubmit: async (val) => {
            console.log("asdasd", {
                ...val,
                models: val?.models?.map(item => { return item.value }),
                role: "SELLER",
                ibanBankName: "-",
                ibanAccountName: "-",
                ibanNumber: "-",
                companyName: "-",
                vd: "-",
                vkn: "-",
                city: "-",
                district: "-",
                openAddress: "-",
                postcode: "-",
                identityNumber: "-",
                phoneNumber: "-",
            })
            const response = await Queries.createUser({
                ...val,
                models: val?.models?.map(item => { return item.value }),
                role: "SELLER",
                ibanBankName: "-",
                ibanAccountName: "-",
                ibanNumber: "-",
                companyName: "-",
                vd: "-",
                vkn: "-",
                city: "-",
                district: "-",
                openAddress: "-",
                postcode: "-",
                identityNumber: "-",
                phoneNumber: "-",
                birthDate: new Date(),
                gender: "MALE",
                authType:"EMAIL"
            });
            
            if (response?.user?._id) {
                toast.success("Satıcı başarılı bir şekilde oluşturuldu");
                handleReset();
            } else {
                toast.error("Satıcı oluşturulurken beklenmeyen bir hata meydana geldi. Lütfen tekrar deneyiniz")
            }
        },
        validationSchema: sellerSchema
    })

    return (
        <>
            <Form.Group className='flex flex-col gap-2 max-h-[55vh] overflow-auto'>
                <div className='flex flex-col gap-1'>
                    <Form.Label>Ad</Form.Label>
                    <Form.Control
                        name='name'
                        type="text"
                        value={values.name}
                        onChange={handleChange("name")} />
                    {errors.name && touched.name && (
                        <div className='bg-red-100 text-red-700 p-2 rounded'>
                            {errors.name}
                        </div>
                    )}
                </div>
                <div className='flex flex-col gap-1'>
                    <Form.Label>Soyad</Form.Label>
                    <Form.Control
                        name='surname'
                        type="text"
                        value={values.surname}
                        onChange={handleChange("surname")} />
                    {errors.surname && touched.surname && (
                        <div className='bg-red-100 text-red-700 p-2 rounded'>
                            {errors.surname}
                        </div>
                    )}
                </div>
                <div className='flex flex-col gap-1'>
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                        name='email'
                        type="text"
                        value={values.email}
                        onChange={handleChange("email")} />
                    {errors.email && touched.email && (
                        <div className='bg-red-100 text-red-700 p-2 rounded'>
                            {errors.email}
                        </div>
                    )}
                </div>
                <div className='flex flex-col gap-1'>
                    <Form.Label>Şifre</Form.Label>
                    <Form.Control
                        name='password'
                        type="text"
                        value={values.password}
                        onChange={handleChange("password")} />
                    {errors.password && touched.password && (
                        <div className='bg-red-100 text-red-700 p-2 rounded'>
                            {errors.password}
                        </div>
                    )}
                </div>
                <div className='flex flex-col gap-1 mb-3'>
                    <Form.Label>Model</Form.Label>
                    <Select
                        value={values?.models}
                        isMulti
                        isSearchable
                        onInputChange={e => setSearchQuery({ ...searchQuery, filterName: e })}
                        onChange={(e) =>
                            setValues({ ...values, models: e })
                        }
                        options={models?.map(item => { return { value: item?._id, label: item?.title } })}
                    />
                    {errors.models && touched.models && (
                        <div className='bg-red-100 text-red-700 p-2 rounded'>
                            {errors.models}
                        </div>
                    )}
                </div>
                <button type='submit' onClick={handleSubmit} className="buttonPurple px-4 py-2 my-3 text-center  rounded-md shadow-sm w-full">
                    Ekle
                </button>
            </Form.Group>
        </>
    )
}

export default AddSellerForm