import React, { useEffect, useRef, useState } from "react";
import { Toolbar } from "@material-ui/core";
import { useStyles } from "../styles";
import CustomDrawer from "../../components/CustomDrawer";
import { Form, InputGroup } from "react-bootstrap";
import ReactQuill from "react-quill";
import { BiSolidCameraPlus } from "react-icons/bi";
import * as Queries from '../../utils/queries'
import { useQuery } from "react-query";
import { useNavigate, useSearchParams } from "react-router-dom";
import toast from "react-hot-toast";
import { Pagination } from "@mui/material";
import { MagnifyingGlassIcon } from "@radix-ui/react-icons";
import Select from 'react-select'
import DetailsPagesProductCard from '../../components/DetailsPagesProductCard'

const CategoryDetail = () => {
  const navigate = useNavigate();
  const classes = useStyles();
  const [searchParams] = useSearchParams();
  const [categoryData, setCategoryData] = useState(null);
  const [searchQuery, setSearchQuery] = useState({
    page: 1,
    limit: 10,
    filterName: "",
    sorted: { value: "new", label: "En Yeni" }
  });
  const fileInputRef = useRef(null);

  const sortedList = [
    { value: "new", label: "En Yeni" },
    { value: "price_asc", label: "Fiyat Artan" },
    { value: "price_desc", label: "Fiyat Azalan" }
  ]

  const { data: category, refetch: categoryRefetch } = useQuery(
    ["findCategoryById", searchParams.get("id")],
    () => Queries.findCategoryById(searchParams.get("id")),
    {
      onError: (err) => {
        console.log("err", err)
      },
      placeholderData: null,
    }
  );

  const {
    data: { page: productGroupsData, totalCount, totalPage },
  } = useQuery(
    ["LIST_PRODUCT_GROUPS", {
      ...searchQuery,
      categoryId: searchParams.get("id"),
      sorted: searchQuery.sorted.value,
    }],
    () => {
      return Queries.listProductGroups(
        new URLSearchParams({
          ...searchQuery,
          categoryId: searchParams.get("id"),
          sorted: searchQuery.sorted.value,
        }).toString()
      );
    },
    {
      placeholderData: {
        page: [],
        totalCount: 0,
        totalPage: 0
      },
      keepPreviousData: false,
    }
  );

  const handleUpdateCategory = async (data) => {
    const response = await Queries.updateCategory(searchParams.get("id"), data);
    if (response?._id) {
      toast.success("Kategori başarılı bir şekilde güncellendi");
    } else {
      toast.error("Kategori güncellenirken bir hata oluştu. Lütfen tekrar deneyiniz")
    }
    categoryRefetch();
  } 

  const handleDelete = async () => {
    const response = await Queries.deleteCategory(searchParams.get("id"));
    if (response?._id) {
      toast.success("Kategori başarılı bir şekilde silindi");
    } else {
      toast.error("Kategori silinirken bir hata oluştu. Lütfen tekrar deneyiniz")
    }
    navigate("/categories")
  }

  const handleImagesUpload = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const response = await Queries.uploadMedia(file);
      handleUpdateCategory({
        photo: response?.secure_url
      });
    } else {
      toast.error('Fotoğraf Seçilirken Bir Hata Oluştu. Lütfen Tekrar Deneyiniz');
      e.target.value = null;
    }
  };

  const handleImageInput = () => {
    fileInputRef.current.click();
  };

  useEffect(() => {
    if (category) {
      setCategoryData(category)
    }
  }, [category])

  return (
    <div className={classes.root}>
      <CustomDrawer />
      <main className={classes.content}>
        <Toolbar />

        <div>
          <div className=" p-3 flex flex-col gap-3">
            <div className="bg-white p-3 border shadow-sm rounded-md">
              <h5 className="font-semibold afterLine">Temel Bilgi</h5>

              <input
                type="file"
                accept="image/*"
                style={{ display: 'none' }}
                ref={fileInputRef}
                onChange={handleImagesUpload}
              />
              {categoryData?.photo ?
                <div className="flex flex-1 justify-center items-center">
                  <img
                    src={categoryData?.photo}
                    alt="photo"
                    className="w-64 h-64 rounded border shadow-md"
                  />
                </div>
                :
                <></>}

              <div className="flex gap-5 my-3">
                <Form.Group className="flex flex-1 flex-col">
                  <Form.Label>Kategori Adı *</Form.Label>
                  <Form.Control
                    type="text"
                    value={categoryData?.title}
                    onChange={(e) => setCategoryData({ ...categoryData, title: e.target.value })} />
                </Form.Group>
              </div>
              <div className="mb-3">
                <Form.Label>Açıklama</Form.Label>
                <ReactQuill
                  theme="snow"
                  value={categoryData?.description}
                  onChange={(value) => setCategoryData({ ...categoryData, description: value })} />
              </div>

              <button onClick={handleImageInput} className="h-[100px] w-full rounded-md bg-slate-200 flex flex-col gap-3 items-center justify-center">
                <span>
                  <BiSolidCameraPlus size={30} />
                </span>
                {categoryData?.photo ? "Görsel Değiştir" : "Görsel Ekle"}
              </button>

              <button onClick={() => {
                if (categoryData?.title) {
                  handleUpdateCategory({
                    title: categoryData?.title,
                    description: categoryData?.description,
                  });
                }
              }} className="px-3 py-2 rounded buttonPurple mt-3">
                Kaydet
              </button>
            </div>

            <div className="bg-white p-3 shadow-sm border rounded-md flex flex-col gap-1">
              <h5 className="font-semibold afterLine">Ürünler</h5>

              <Form.Group className="flex flex-1 flex-col  my-3">
                <Form.Label>Sıralama Ölçütü</Form.Label>
                <Select
                  value={searchQuery.sorted}
                  onChange={(value) => setSearchQuery({ ...searchQuery, sorted: value })}
                  options={sortedList}
                />
              </Form.Group>

              <Form.Group className="flex flex-1 flex-row space-x-1 border border-gray-200 rounded items-center mb-3">
                <label
                  htmlFor="searchInput"
                  className="px-2 py-3 flex items-center justify-center"
                >
                  <MagnifyingGlassIcon />
                </label>
                <input
                  id="searchInput"
                  className="h-full flex flex-1"
                  placeholder="Tabloda Arama Yapın"
                  value={searchQuery.filterName}
                  onChange={(e) => setSearchQuery({ ...searchQuery, filterName: e.target.value })}
                />
              </Form.Group>

              {totalCount !== 0 ?
                <div className="flex flex-col">
                  {productGroupsData?.map(product => {
                    return (
                      <DetailsPagesProductCard
                        key={product?._id}
                        product={product}
                      />
                    )
                  })}
                </div>
                :
                <div className="flex flex-1 p-2 rounded bg-blue-100 text-blue-700">
                  Kategoriye bağlı ürün bulunmamakta
                </div>}

              <div className="flex flex-1 justify-center items-center mt-2">
                <Pagination
                  page={searchQuery.page}
                  onChange={(_, value) => setSearchQuery({ ...searchQuery, page: value })}
                  count={totalPage}
                  shape="rounded"
                  color="primary"
                  variant="outlined"
                />
              </div>
            </div>

            <div className="grid grid-cols-2 gap-3 border border-gray-200  bg-white p-3  shadow-sm rounded-md">
              <div>
                <h5 className="">
                  Arama Motoru Optimizasyonu (SEO)
                </h5>
                <InputGroup size="md">
                  <InputGroup.Text id="inputGroup-sizing-lg">/</InputGroup.Text>
                  <Form.Control
                    aria-label="Large"
                    aria-describedby="inputGroup-sizing-sm"
                    value={categoryData?.slug}
                    onChange={(e) => setCategoryData({ ...categoryData, slug: e.target.value })}
                  />
                </InputGroup>
                <Form.Group className="mt-3">
                  <Form.Label>Sayfa Başlığı</Form.Label>
                  <Form.Control
                    type="text"
                    className="max-w-[738px]"
                    value={categoryData?.pageTitle}
                    onChange={(e) => setCategoryData({ ...categoryData, pageTitle: e.target.value })} />
                  <Form.Label>Açıklama</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    value={categoryData?.pageDescription}
                    onChange={(e) => setCategoryData({ ...categoryData, pageDescription: e.target.value })} />

                  <div className="w-full flex flex-row gap-6 justify-between">
                    <button onClick={() => {
                      if (categoryData?.slug) {
                        handleUpdateCategory({
                          slug: categoryData?.slug,
                          pageTitle: categoryData?.pageTitle,
                          pageDescription: categoryData?.pageDescription,
                        });
                      }
                    }} className="px-3 py-2 rounded buttonPurple mt-3">
                      Kaydet
                    </button>

                    <button onClick={() => {
                      handleDelete()
                    }} className="px-3 py-2 rounded bg-red-600 hover:bg-red-700 text-white mt-3">
                      Sil
                    </button>
                  </div>
                </Form.Group>
              </div>
              <div>
                <h5 className="text-purple-800">Önizleme</h5>
                <div className="p-3 bg-gray-100 border border-radius shadow-sm rounded-md">
                  <p>sutok.com/{categoryData?.slug}</p>
                  <p className="text-purple-900">{categoryData?.pageTitle}</p>
                  <p>
                    {categoryData?.pageDescription}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default CategoryDetail;
