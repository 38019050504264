import React from 'react'
import moment from 'moment/moment'
import { FaTrash } from 'react-icons/fa6'
import * as Queries from '../../utils/queries'
import toast from 'react-hot-toast'
import { useQueryClient } from 'react-query'

const NotificationCard = ({ data }) => {

    const queryClient = useQueryClient();

    const handleDelete = async () => {
        console.log("data",data)
        const response = await Queries.deleteNotificationFindUuid(data?._id);
        if (response?.success) {
            toast.success("Bildirim başarılı bir şekilde silindi");
        } else {
            toast.error("Bildirim Silinirken bir hata oluştu. Lütfen tekrar deneyiniz")
        }
        queryClient.invalidateQueries(["listNotificationsForAdminPanel", { page: 1, limit: 10, title: "" }])
    }

    return (
        <tr class=" border-b">
            <th
                scope="row"
                className="px-6 py-4 font-medium whitespace-nowrap "
            >
                {data?.title}
            </th>
            <td className="px-6 py-4">
                {data?.description}
            </td>
            <td className="px-6 py-4">{data?.totalCount}</td>
            <td className="px-6 py-4">{data?.viewedCount}</td>
            <td className="px-6 py-4">
                {moment(data?.createdAt).format('DD.MM.YYYY hh:mm')}
            </td>
            <td className="px-6 py-4 text-center">
                <button onClick={() => handleDelete()}>
                    <FaTrash color='red' />
                </button>
            </td>
        </tr>
    )
}

export default NotificationCard