import React, { useEffect, useState } from "react";
import {
  Toolbar,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@material-ui/core";
import { Pagination } from "@mui/material";
import { useQuery } from "react-query";
import ProductGroupDialog from "./ProductGroupDialog";
import * as Queries from "../../utils/queries";
import CustomDrawer from "../../components/CustomDrawer";
import ProductsDrawer from "./ProductsDrawer";
import toast from "react-hot-toast";
import {
  MagnifyingGlassIcon,
  Cross2Icon,
  MixerHorizontalIcon,
  CheckIcon
} from "@radix-ui/react-icons";
import "../../checkbox.css";
import ProductGroupCard from "./ProductGroupCard";
import * as Popover from "@radix-ui/react-popover";
import "../../popover.css";
import { useStyles } from "../styles";
import FilterPopover from "./FilterPopover";
import { IoClose, IoFilterOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import * as Checkbox from '@radix-ui/react-checkbox';
import '../../checkbox.css';
import { Add as AddIcon } from "@material-ui/icons";
import { useAtom } from "jotai";
import { userAtom } from "../../utils/atoms";

const ProductGroups = () => {
  const classes = useStyles();
  const [user, setUser] = useAtom(userAtom)
  const [openProductGroupDialog, setOpenProductGroupDialog] = useState(false);
  const [selectedProductGroup, setSelectedProductGroup] = useState();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [showDrawer, setShowDrawer] = useState(false);
  const [searchQuery, setSearchQuery] = useState({
    page: 1,
    filterName: "",
    category: "",
    stock: "",
    limit: 10,
    sellerRole: ""
  });
  const [openFilterPopover, setOpenFilterPopover] = useState(false);
  const [sorted, setSorted] = useState("");
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [selectedBatchProcessing, setSelectedBatchProcessing] = useState("");
  const [filter, setFilter] = useState({
    categoryLabel: "",
    categoryContaining: "",
    brandLabel: "",
    brandContaining: "",
    modelLabel: "",
    modelContaining: "",
  });

  const sortList = [
    { id: "price_asc", label: "Artan Fiyat" },
    { id: "price_desc", label: "Azalan Fiyat" },
    { id: "stock_asc", label: "Artan Stok" },
    { id: "stock_desc", label: "Azalan Stok" },
    { id: "new", label: "Yeniden Eskiye" },
    { id: "old", label: "Eskiden Yeniye" },
  ]

  const {
    data,
    refetch: productGroupsRefetch,
  } = useQuery(
    ["LIST_PRODUCT_GROUPS", searchQuery],
    () => {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;

      return Queries.listProductGroups(
        new URLSearchParams({
          ...searchQuery,
        }).toString()
      );
    },
    {
      placeholderData: {
        page: [],
        totalCount: 0,
      },
      keepPreviousData: false,
    }
  );
  const navigation = useNavigate();

  const { data: categoriesData } = useQuery(
    ["LIST_CATEGORIES"],
    () => Queries.listCategories("page=1&limit=1000"),
    {
      onError: (err) => {
        console.log("err", err);
      },
      placeholderData: {
        page: [],
      },
    }
  );

  const handleSearch = (event) => {
    setSearchQuery({
      ...searchQuery,
      filterName: event.target.value,
      page: 1
    });
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  const handleSelect = async (value, item) => {
    if (value) {
      setSelectedProducts([...selectedProducts, item]);
    } else {
      const list = selectedProducts.filter(
        (product) => product._id !== item._id
      );
      setSelectedProducts(list);
    }
  };

  const handleSubmitFiltre = async () => {
    if (selectedBatchProcessing === "delete") {
      for (let i = 0; i < selectedProducts.length; i++) {
        Queries.deleteGroup(selectedProducts[i]._id);
        productGroupsRefetch();
      }
    }
  };

  const handleChangePage = (event, value) => {
    setSearchQuery({
      ...searchQuery,
      page: value,
    });
  };

  const handleDeleteGroups = async () => {
    for (let i = 0; i < selectedProducts.length; i++) {
      await Queries.deleteGroup(selectedProducts[i]._id);
    }

    productGroupsRefetch();
    toast.success("Seçilen ürünler başarılı bir şekilde silindi");
    setSelectedProducts([]);
  }

  useEffect(() => {
    const product = JSON.parse(localStorage.getItem("newProduct"));
    if (product?._id) {
      toast.success(
        "Ürün oluşturuldu. Lütfen Ürün İçin En az Bir Varyant Oluşturun"
      );
      setShowDrawer(true);
      setSelectedProductGroup(product);
      localStorage.removeItem("newProduct");
    }
  }, [localStorage.getItem("newProduct")]);

  useEffect(() => {
    if (user) {
      setSearchQuery({ ...searchQuery, sellerRole: user?.role })
    }
  }, [user])

  return (
    <div className="flex flex-1 bg-white">
      <CustomDrawer />
      <main className={classes.content}>
        <Toolbar />

        <div className="flex flex-1 flex-row space-x-4 items-center px-3 bg-white p-3 shadow-sm rounded-md border">
          <div className="flex flex-1 text-xl font-semibold">Ürünler</div>

          <button onClick={() => navigation("/addProduct")} className="flex flex-row space-x-2 items-center justify-center buttonPurple px-3 py-2 rounded">
            <AddIcon />
            <span>Ürün Oluştur</span>
          </button>
        </div>

        <div className="flex flex-1 flex-row space-x-4 px-3 bg-white p-3 shadow-sm rounded-md border">
          <div className="flex flex-1 flex-row space-x-4">
            <div className="w-72 flex flex-row space-x-1 border border-gray-200 rounded items-center">
              <label
                htmlFor="searchInput"
                className="px-2 py-3 flex items-center justify-center"
              >
                <MagnifyingGlassIcon />
              </label>
              <input
                id="searchInput"
                className="h-full flex flex-1"
                placeholder="Tabloda Arama Yapın"
                value={searchQuery.filterName}
                onChange={handleSearch}
              />
            </div>

            <button
              className="flex items-center gap-2 bg-[#6f55ff] text-white p-2 rounded-md"
              onClick={() => setOpenFilterPopover(!openFilterPopover)}
            >
              <IoFilterOutline /> Filtre
            </button>

            {selectedProducts.length > 0 && (
              <div className="flex items-center gap-3 border rounded-md">
                <small className="ml-2">{selectedProducts.length} Adet Ürün Seçildi</small>
                <button onClick={() => handleDeleteGroups()} className="hover:bg-gray-100 text-red-700 flex items-center gap-3 w-[80px] p-2 h-full" style={{ borderLeft: "1px solid #DEE2E6" }}>
                  {" "}
                  <IoClose /> Sil{" "}
                </button>
              </div>
            )}
          </div>

          <Popover.Root>
            <Popover.Trigger asChild>
              <button className="px-3 py-2 borderGray rounded flex flex-row space-x-2 items-center justify-center">
                <MixerHorizontalIcon />
              </button>
            </Popover.Trigger>
            <Popover.Portal>
              <Popover.Content className="PopoverContent mr-4" sideOffset={5}>
                <div className="flex flex-col space-y-3">
                  <p className="font-semibold">Sıralama</p>

                  <select
                    value={sorted}
                    onChange={(e) => setSorted(e.target.value)}
                    className="borderGray p-2 rounded"
                  >
                    <option disabled value={""} selected={sorted === ""}>
                      Seçiniz
                    </option>
                    {sortList?.map(item => {
                      return (
                        <option
                          value={item.id}
                          selected={sorted === item.id}
                        >
                          {item.label}
                        </option>
                      )
                    })}
                  </select>

                  <div className="flex flex-1 flex-row space-x-4">
                    <button
                      onClick={() => setSorted("")}
                      className="px-4 py-2 border border-gray-100 hover:bg-gray-100 rounded"
                    >
                      Sıfırla
                    </button>

                    <button
                      onClick={() =>
                        setSearchQuery({ ...searchQuery, sorted: sorted })
                      }
                      className="px-4 py-2 buttonPurple rounded"
                    >
                      Uygula
                    </button>
                  </div>
                </div>
                <Popover.Close className="PopoverClose" aria-label="Close">
                  <Cross2Icon />
                </Popover.Close>
                <Popover.Arrow className="PopoverArrow" />
              </Popover.Content>
            </Popover.Portal>
          </Popover.Root>
        </div>

        <div
          className={`${openFilterPopover
            ? "bg-white p-3 shadow-sm border rounded-md"
            : "hidden"
            }`}
        >
          <FilterPopover
            key={"filterPopover"}
            categoriesData={categoriesData}
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            filter={filter}
            setFilter={setFilter}
          />
        </div>

        <div className=" bg-white p-3 shadow-sm rounded-md border">
          <TableContainer component={Paper}>
            <Table>
              <TableHead className="bg-[#EEF2F6]">
                <TableRow>
                  <TableCell>
                    <div className="flex items-center gap-3 ">
                      <Checkbox.Root className="CheckboxRoot" id="c1"
                        onCheckedChange={(value) => {
                          if (value) {
                            setSelectedProducts(data?.page)
                          } else {
                            setSelectedProducts([]);
                          }
                        }}>
                        <Checkbox.Indicator className="CheckboxIndicator">
                          <CheckIcon />
                        </Checkbox.Indicator>
                      </Checkbox.Root>
                      <span>Fotoğrafı</span>
                    </div>
                  </TableCell>
                  <TableCell>Adı</TableCell>
                  <TableCell>Fiyatı</TableCell>
                  <TableCell>Stok</TableCell>
                  <TableCell>Detaylar</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.page?.length !== 0 &&
                  data?.page?.map((productGroup) => (
                    <ProductGroupCard
                      key={productGroup._id}
                      productGroup={productGroup}
                      handleSelect={handleSelect}
                      selectedProducts={selectedProducts}
                    />
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <div className="flex flex-1 justify-center py-2">
            <Pagination
              page={searchQuery.page}
              count={data?.totalPage}
              variant="outlined"
              shape="rounded"
              color="primary"
              onChange={handleChangePage}
            />
          </div>
        </div>
      </main>

      {openProductGroupDialog && (
        <ProductGroupDialog
          openProductGroupDialog={openProductGroupDialog}
          setOpenProductGroupDialog={setOpenProductGroupDialog}
          initialData={selectedProductGroup}
          setShowDrawer={setShowDrawer}
          setSelectedProductGroup={setSelectedProductGroup}
        />
      )}

      {showDrawer && selectedProductGroup && (
        <ProductsDrawer
          showDrawer={showDrawer}
          setShowDrawer={setShowDrawer}
          selectedProductGroup={selectedProductGroup}
        />
      )}

      <Snackbar
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        message="İşlem başarılı."
      />
    </div>
  );
};

export default ProductGroups;
