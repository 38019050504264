import React, { useEffect, useRef, useState } from "react";
import { Toolbar } from "@material-ui/core";
import { Pagination } from '@mui/material'
import { useStyles } from "../styles";
import CustomDrawer from "../../components/CustomDrawer";
import { Form, InputGroup } from "react-bootstrap";
import ReactQuill from "react-quill";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useQuery } from "react-query";
import * as Queries from '../../utils/queries'
import Select from 'react-select'
import toast, { Toaster } from "react-hot-toast";
import DetailsPagesProductCard from "../../components/DetailsPagesProductCard";
import { CameraIcon, MagnifyingGlassIcon, PlusIcon } from "@radix-ui/react-icons";

const BrandDetail = () => {
  const classes = useStyles();
  const [searchParams] = useSearchParams();
  const [brandData, setBrandData] = useState(null);
  const [searchQuery, setSearchQuery] = useState({
    page: 1,
    limit: 10,
    filterName: "",
    sorted: { value: "new", label: "En Yeni" }
  });
  const logoFileInputRef = useRef(null);
  const coverFileInputRef = useRef(null);
  const backgroundFileInputRef = useRef(null);
  const navigate = useNavigate();

  const sortedList = [
    { value: "new", label: "En Yeni" },
    { value: "price_asc", label: "Fiyat Artan" },
    { value: "price_desc", label: "Fiyat Azalan" }
  ]

  const { data: brand, refetch: brandRefetch } = useQuery(
    ["getBrandDetail", searchParams.get("id")],
    () => Queries.getBrandDetail(searchParams.get("id")),
    {
      onError: (err) => {
        console.log("err", err)
      },
      placeholderData: null,
    }
  );

  const {
    data: { page: productGroupsData, totalCount, totalPage },
  } = useQuery(
    ["LIST_PRODUCT_GROUPS", {
      ...searchQuery,
      brandId: searchParams.get("id"),
      sorted: searchQuery.sorted.value,
    }],
    () => {
      return Queries.listProductGroups(
        new URLSearchParams({
          ...searchQuery,
          brandId: searchParams.get("id"),
          sorted: searchQuery.sorted.value,
        }).toString()
      );
    },
    {
      placeholderData: {
        page: [],
        totalCount: 0,
        totalPage: 0
      },
      keepPreviousData: false,
    }
  );

  const handleUpdateBrand = async (data) => {
    const response = await Queries.updateBrand(searchParams.get("id"), data);
    if (response?._id) {
      toast.success("Marka başarılı bir şekilde güncellendi");
    } else {
      toast.error("Marka güncellenirken bir hata meydana geldi. Lütfen tekrar deneyiniz");
    }
    brandRefetch();
  }

  const handleLogoUpload = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const response = await Queries.uploadMedia(file);
      handleUpdateBrand({
        logo: response?.secure_url
      });
    } else {
      toast.error('Logo Görseli Seçilirken Bir Hata Oluştu. Lütfen Tekrar Deneyiniz');
      e.target.value = null;
    }
  };

  const handleCoverUpload = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const response = await Queries.uploadMedia(file);
      handleUpdateBrand({
        cover: response?.secure_url
      });
    } else {
      toast.error('Kapak Detay Görseli Seçilirken Bir Hata Oluştu. Lütfen Tekrar Deneyiniz');
      e.target.value = null;
    }
  };

  const handleBackgroundUpload = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const response = await Queries.uploadMedia(file);
      handleUpdateBrand({
        background: response?.secure_url
      });
    } else {
      toast.error('Arkaplan Görseli Seçilirken Bir Hata Oluştu. Lütfen Tekrar Deneyiniz');
      e.target.value = null;
    }
  };

  const handleImageInput = (imageInputRef) => {
    imageInputRef.current.click();
  };

  const handleDelete = async () => {
    const response = await Queries.deleteBrand(searchParams.get("id"));
    console.log(response)
    if (response?._id) {
      toast.success("Marka başarılı bir şekilde silindi");
    } else {
      toast.error("Marka silinirken bir hata oluştu. Lütfen tekrar deneyiniz")
    }
    navigate("/brands")
  }

  useEffect(() => {
    if (brand?._id) {
      setBrandData(brand);
      console.log("brand", brand)
    }
  }, [brand])

  return (
    <div className={classes.root}>
      <CustomDrawer />
      <main className={classes.content}>
        <Toolbar />

        <div>
          <div className="flex flex-col gap-3">
            <div className="bg-white p-3 shadow-sm border rounded-md flex flex-col gap-3">
              <h5 className="font-semibold afterLine">Temel Bilgi</h5>

              <div className="flex gap-5 ">
                <Form.Group className="flex flex-1 flex-col">
                  <Form.Label>Marka Adı *</Form.Label>
                  <Form.Control
                    type="text"
                    value={brandData?.title}
                    onChange={(e) => setBrandData({ ...brandData, title: e.target.value })}
                  />
                </Form.Group>
              </div>
              <div className="">
                <Form.Label>Açıklama</Form.Label>

                <ReactQuill
                  theme="snow"
                  value={brandData?.description}
                  onChange={(value) => setBrandData({ ...brandData, description: value })}
                />
              </div>

              <button onClick={() => {
                handleUpdateBrand({
                  title: brandData?.title,
                  description: brandData?.description,
                })
              }} className="w-max px-3 py-2 rounded buttonPurple">
                Kaydet
              </button>
            </div>

            <div className="bg-white p-3 shadow-sm border rounded-md flex flex-col gap-3">
              <h5 className="font-semibold afterLine">Görseller</h5>

              <input
                type="file"
                accept="image/*"
                style={{ display: 'none' }}
                ref={coverFileInputRef}
                onChange={handleCoverUpload}
              />

              <input
                type="file"
                accept="image/*"
                style={{ display: 'none' }}
                ref={logoFileInputRef}
                onChange={handleLogoUpload}
              />

              <input
                type="file"
                accept="image/*"
                style={{ display: 'none' }}
                ref={backgroundFileInputRef}
                onChange={handleBackgroundUpload}
              />

              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-3">
                <div className="flex flex-1 flex-col space-y-1">
                  <div className="font-semibold">Kapak Detay Görseli</div>

                  <div onClick={() => handleImageInput(coverFileInputRef)} className="flex flex-1 cursor-pointer">
                    <img
                      src={brand?.cover}
                      alt="cover"
                      className="w-full h-56 border"
                    />
                  </div>
                </div>

                <div className="flex flex-1 flex-col space-y-1">
                  <div className="font-semibold">Arkaplan Görseli</div>

                  <div onClick={() => handleImageInput(backgroundFileInputRef)} className="flex flex-1 cursor-pointer">
                    <img
                      src={brand?.background}
                      alt="background"
                      className="w-full h-56 border"
                    />
                  </div>
                </div>

                <div className="flex flex-1 flex-col space-y-1">
                  <div className="font-semibold">Logo Görseli</div>

                  <div onClick={() => handleImageInput(logoFileInputRef)} className="flex flex-1 cursor-pointer">
                    <img
                      src={brand?.logo}
                      alt="logo"
                      className="w-full h-56 border"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="bg-white p-3 shadow-sm border rounded-md flex flex-col gap-1">
              <h5 className="font-semibold afterLine">Ürünler</h5>

              <Form.Group className="flex flex-1 flex-col  my-3">
                <Form.Label>Sıralama Ölçütü</Form.Label>
                <Select
                  value={searchQuery.sorted}
                  onChange={(value) => setSearchQuery({ ...searchQuery, sorted: value })}
                  options={sortedList}
                />
              </Form.Group>

              <Form.Group className="flex flex-1 flex-row space-x-1 border border-gray-200 rounded items-center mb-3">
                <label
                  htmlFor="searchInput"
                  className="px-2 py-3 flex items-center justify-center"
                >
                  <MagnifyingGlassIcon />
                </label>
                <input
                  id="searchInput"
                  className="h-full flex flex-1"
                  placeholder="Tabloda Arama Yapın"
                  value={searchQuery.filterName}
                  onChange={(e) => setSearchQuery({ ...searchQuery, filterName: e.target.value })}
                />
              </Form.Group>

              {totalCount !== 0 ?
                <div className="flex flex-col">
                  {productGroupsData?.map(product => {
                    return (
                      <DetailsPagesProductCard
                        key={product?._id}
                        product={product}
                      />
                    )
                  })}
                </div>
                :
                <div className="flex flex-1 p-2 rounded bg-blue-100 text-blue-700">
                  Markaya bağlı ürün bulunmamakta
                </div>}

              <div className="flex flex-1 justify-center items-center mt-2">
                <Pagination
                  page={searchQuery.page}
                  onChange={(_, value) => setSearchQuery({ ...searchQuery, page: value })}
                  count={totalPage}
                  shape="rounded"
                  color="primary"
                  variant="outlined"
                />
              </div>
            </div>

            <div className="grid grid-cols-2 gap-3 border bg-white shadow-sm rounded-md p-3">
              <div>
                <h5 className=" py-2">Arama Motoru Optimizasyonu (SEO)</h5>
                <InputGroup size="md">
                  <InputGroup.Text id="inputGroup-sizing-lg">/</InputGroup.Text>
                  <Form.Control
                    aria-label="Large"
                    aria-describedby="inputGroup-sizing-sm"
                    value={brandData?.slug}
                    onChange={(e) => setBrandData({ ...brandData, slug: e.target.value })}
                  />
                </InputGroup>
                <Form.Group className="mt-3">
                  <Form.Label>Sayfa Başlığı</Form.Label>
                  <Form.Control
                    type="text"
                    className="max-w-[738px]"
                    value={brandData?.pageTitle}
                    onChange={(e) => setBrandData({ ...brandData, pageTitle: e.target.value })}
                  />
                  <Form.Label>Açıklama</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    value={brandData?.pageDescription}
                    onChange={(e) => setBrandData({ ...brandData, pageDescription: e.target.value })}
                  />
                </Form.Group>

                <div className="w-full flex flex-row gap-6 justify-between">
                  <button onClick={() => {
                    handleUpdateBrand({
                      slug: brandData?.slug,
                      pageTitle: brandData?.pageTitle,
                      pageDescription: brandData?.pageDescription,
                    })
                  }} className="px-3 py-2 rounded buttonPurple mt-3">
                    Kaydet
                  </button>

                  <button onClick={() => {
                    handleDelete()
                  }} className="px-3 py-2 rounded bg-red-600 hover:bg-red-700 text-white mt-3">
                    Sil
                  </button>
                </div>
              </div>
              <div>
                <h5 className="py-2 text-purple-800">Önizleme</h5>
                <div className="p-3 bg-gray-100 border border-radius shadow-sm">
                  <p>sutok.com/{brandData?.slug}</p>
                  <p className="text-purple-900">{brandData?.pageTitle}</p>
                  <p>
                    {brandData?.pageDescription}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default BrandDetail;
