import React, { useEffect, useState } from "react";
import {
    Toolbar,
    Typography,
} from "@material-ui/core";
import { useQuery, useQueryClient } from "react-query";
import { useStyles } from "../styles";
import * as Queries from "../../utils/queries";
import CustomDrawer from "../../components/CustomDrawer";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import { object, string } from "yup";
import { FaArrowDown, FaMagnifyingGlass } from "react-icons/fa6";
import { FaRegSmile } from "react-icons/fa";
import * as Popover from '@radix-ui/react-popover';
import emojiData from '@emoji-mart/data'
import Picker from '@emoji-mart/react'
import '../../popover.css';
import { Cross2Icon } from "@radix-ui/react-icons";
import CustomLoading from "../../components/CustomLoading";
import { useAtomValue } from "jotai";
import { userAtom } from "../../utils/atoms";
import NotificationCard from "../../components/NotificationCard";
import { Pagination } from "@mui/material";

const createNotificationValidations = () => object({
    title: string()
        .required('Başlık Boş Geçilemez'),
    description: string()
        .required('Açıklama Boş Geçilemez'),
});

const Notifications = () => {
    const classes = useStyles();
    const queryClient = useQueryClient();
    const user = useAtomValue(userAtom)
    const [loading, setLoading] = useState(false)
    const [clicked, setClicked] = useState(false)
    const [searchQuery, setSearchQuery] = useState({
        page: 1,
        limit: 10,
        title: ""
    })

    const { isLoading, data } = useQuery(
        ["listNotificationsForAdminPanel", {
            ...searchQuery,
        }],
        () => {
            /*document.body.scrollTop = 0; // For Safari
            document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera*/

            return Queries.listNotificationsForAdminPanel(
                new URLSearchParams({
                    ...searchQuery,
                }).toString()
            );
        },
        {
            placeholderData: {
                datas: [],
                totalCount: 0,
                totalPage: 0
            },
            keepPreviousData: false,
        }
    );

    const { handleSubmit, handleReset, handleChange, setValues, resetForm, values, errors } = useFormik({
        initialValues: {
            title: "",
            description: "",
        },
        onSubmit: async (val) => {
            setLoading(true);

            const response = await Queries.createNotification({
                ...val,
                user: user?._id
            });

            if (response?.success) {
                toast.success("Bildirim başarılı bir şekilde kullanıcılara iletildi");
                resetForm();
            } else {
                toast.error("Bildirim iletilirken bir hata oluştu. Lütfen tekrar deneyiniz")
            }

            queryClient.invalidateQueries(["listNotificationsForAdminPanel", { page: 1, limit: 10, title: "" }])
            setLoading(false);
        },
        validationSchema: createNotificationValidations
    })

    useEffect(() => {
        if (Object.entries(errors).length !== 0 && clicked) {
            const list = Object.entries(errors).map((item) => item[1]);
            toast.error(list[0])
            setClicked(false);
        }
    }, [errors, clicked]);

    return (
        <div className={classes.root}>
            <CustomDrawer />
            <main className={classes.content}>
                <Toolbar />
                <Typography variant="h6" gutterBottom>
                    Bildirimler
                </Typography>

                <form onSubmit={handleSubmit} className="flex flex-col gap-3 my-3 bg-white w-full shadow-sm border p-3 rounded-md ">
                    <input
                        className="bg-[#FCFDFD] w-full p-2 rounded-md border"
                        placeholder={"Başlık"}
                        name="title"
                        value={values.title}
                        onChange={handleChange("title")} />
                    <div className="flex flex-1 flex-col bg-[#FCFDFD] border rounded-md px-1">
                        <Popover.Root>
                            <Popover.Trigger asChild>
                                <button type="button" className="flex items-center cursor-pointer gap-2 w-[128px] h-[28px] border rounded-md mt-1 px-1 text-[#787878] text-[12px]">
                                    {" "}
                                    <FaRegSmile /> Emoji Ekle <FaArrowDown />
                                </button>
                            </Popover.Trigger>
                            <Popover.Portal>
                                <Popover.Content className="PopoverContent ml-24" sideOffset={5}>
                                    <Picker data={emojiData} onEmojiSelect={(e) => setValues({
                                        ...values,
                                        description: values.description + e.native
                                    })} />
                                    <Popover.Close className="PopoverClose" aria-label="Close">
                                        <Cross2Icon />
                                    </Popover.Close>
                                    <Popover.Arrow className="PopoverArrow" />
                                </Popover.Content>
                            </Popover.Portal>
                        </Popover.Root>
                        <textarea
                            id=""
                            rows={10}
                            className="bg-[#FCFDFD] w-full px-1 min-h-40 max-h-40"
                            placeholder={"Açıklama"}
                            name="description"
                            value={values.description}
                            onChange={handleChange("description")} />
                    </div>

                    <div className="w-full flex justify-end">
                        {!loading ?
                            <button onClick={(e) => {
                                setClicked(true);
                                handleSubmit(e);
                            }} className="px-4 py-2 rounded-md bg-blue-600 text-white">
                                Gönder
                            </button> : <CustomLoading
                                className="px-10 py-2.5 rounded-md bg-blue-600 text-white flex items-center justify-center"
                                type={"clip"}
                                color={"white"}
                                size={20}
                            />}
                    </div>
                </form>

                <div className="bg-white w-full shadow-sm border p-3 rounded-md ">
                    <div className="flex flex-row items-center justify-between mb-3">
                        <h1 className="text-[24px] font-bold mb-3">
                            Bildirimler Listesi
                        </h1>
                        <div>
                            <div className="relative flex-1 w-[323px]">
                                <input
                                    type="text"
                                    className="rounded-2xl pl-12 bg-[#F5F6FA] h-[38px] w-full border"
                                    placeholder="Arama yap"
                                    value={searchQuery?.title}
                                    onChange={e => setSearchQuery({
                                        ...searchQuery,
                                        title: e.target.value
                                    })}
                                />
                                <span className="absolute left-5 top-[50%] translate-y-[-50%]">
                                    <FaMagnifyingGlass />
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="relative overflow-x-auto">
                        <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                            <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                                <thead className="text-xs text-gray-700  bg-[#F1F4F9] rounded-lg">
                                    <tr>
                                        <th scope="col" className="px-6 py-3 font-bold text-[14px]">
                                            Bildirim Başlığı
                                        </th>
                                        <th scope="col" className="px-6 py-3 font-bold text-[14px]">
                                            Bildirim Açıklaması
                                        </th>
                                        <th scope="col" className="px-6 py-3 font-bold text-[14px]">
                                            Gönderilen Kullanıcı Sayısı
                                        </th>
                                        <th scope="col" className="px-6 py-3 font-bold text-[14px]">
                                            Açan Kullanıcı Sayısı
                                        </th>
                                        <th scope="col" className="px-6 py-3 font-bold text-[14px]">
                                            Tarih
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-6 py-3 text-center font-bold text-[14px]"
                                        >
                                            {" "}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data?.totalCount > 0 ?
                                        data?.datas?.map(item => {
                                            return (
                                                <NotificationCard
                                                    key={item?._id}
                                                    data={item}
                                                />
                                            )
                                        }) : <></>}
                                </tbody>
                            </table>
                            {isLoading ?
                                <CustomLoading
                                    color={"gray"}
                                    size={24}
                                    type={"hash"}
                                    className="flex flex-1 h-72 items-center justify-center border rounded-b-lg" /> : <></>}

                            {data?.totalCount === 0 ?
                                <div className="flex flex-1 h-72 items-center justify-center border rounded-b-lg">
                                    <span>
                                        Bildirim Bulunamadı
                                    </span>
                                </div> : <></>}

                            <div className="flex flex-1 mt-3 justify-center items-center">
                                <Pagination
                                    count={data?.totalPage}
                                    page={searchQuery?.page}
                                    onChange={(_, value) => setSearchQuery({ ...searchQuery, page: value })}
                                    variant="outlined"
                                    shape="rounded"
                                    color="primary"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    );
};

export default Notifications;
